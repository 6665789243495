import { Link } from "react-router-dom";

export default function SuggestionsDropdown(props) {
    const suggestions = ["E-Commerce", "Fintech"];

    if(props.searchText === "") {

        if(!props.barSelected) {

            return <></>
        }

        return buildListHtml(suggestions);
    }
    
    const processedSearchText = props.searchText.toLowerCase();
    const matches = suggestions.filter(s => fuzzyContains(processedSearchText, s));

    if(matches.length === 0) {

        return <></>
    }

    return buildListHtml(matches);
}

function fuzzyContains(searchText, container) {

    let invalidCharacters = 0;
    const maxInvalidCharacters = 1;

    if(searchText.length > container.length) {

        return false;
    }

    let searchTextIndex = 0;
    let containerIndex = 0;

    while(searchTextIndex < searchText.length && containerIndex < container.length) {

        if(searchText[searchTextIndex] !== container[containerIndex]) {

            invalidCharacters++

            if(searchText[searchTextIndex] === container[containerIndex+1]) {
                // Missing character.

                containerIndex++;
            }
        }

        searchTextIndex++;
        containerIndex++;
    }

    return invalidCharacters <= maxInvalidCharacters;
}

function buildListHtml(list) {

    return (
        <ul id="suggestions">
            {
                list.map(i => (
                    <Link to={`/results/${i}`} style={{textDecoration: "none"}}><li><img src="images/search_icon.png" className="search-icon"></img><p>{i.toLowerCase()}</p></li></Link>
                ))                
            }
        </ul>
    )
}