export default function SearchResult(props) {

    return (
        <div class="result-container">
            <div class="job-title">
                <h3>{props.jobTitle}</h3>
            </div>
            <div class="salary-info">
                <p>Salary: {props.salary}</p>
                <button onclick="location.href='[JobLink]'">Apply</button>
            </div>
        </div>
    );
}