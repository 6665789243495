import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SearchBar from '../components/SearchBar'
import SearchResult from '../components/SearchResult'
import '../css/search.css'

export default function SearchResultsPage(props) {

    let { query } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [results, setResults] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [currentQuery, setCurrentQuery] = useState("");

    useEffect(() => {
        fetch(`https://api.searchdev.co.uk/get-jobs-by-category?category=${query}`)
        .then(r => r.json())
        .then(r => {
            setIsLoaded(true);
            console.log(r);
            setResults(r);
        })
        .catch(() => {
            setHasError(true);
            setIsLoaded(true);
        })
    }, [currentQuery]);
    
    console.log(`Query changed: ${currentQuery}`);

    let queryText = "";

    if(isLoaded && hasError) {
        queryText = "Sorry, something went wrong!";
    }
    else if(isLoaded) {
        queryText = `Found ${results.length} for ${query}!${results.length > 0 ? "" : " Try using a different search term."}`
    }

    if(!isLoaded) {

        return (
            <>
                <header>
                    <SearchBar shouldClickDropdown={false} setSearchText={x => {}} setCurrentQuery={setCurrentQuery} />
                    <p>{queryText}</p>
                </header>
                <h1>Loading...</h1>
            </>
        );
    }

    return (
        <>
            <header>
                <SearchBar shouldClickDropdown={false} setSearchText={x => {}} setCurrentQuery={setCurrentQuery} />
                <p>{queryText}</p>
            </header>
            <div id="search-results">
                {
                    results && results.map(r => <SearchResult jobTitle={r.Title} salary={r.Salary == null ? "Not listed" : r.Salary} />)
                }
            </div>
        </>
    );
}