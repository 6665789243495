import {useNavigate} from 'react-router-dom';

export default function SearchBar(props) {
    const navigate = useNavigate();

    if(props.shouldClickDropdown == null || !props.shouldClickDropdown) {

        return (
            <div id="search-bar">
                <input id="search-bar-text" type="text" autocomplete="off" placeholder="e.g Fintech" 
                    onChange={e => props.setSearchText(e.target.value)}
                    onKeyDown={e => {
                            if(e.key == "Enter") {
                                navigate(`/results/${e.target.value}`);
                                props.setCurrentQuery(e.target.value);
                            }
                        }
                    }>
                </input>
                <img src="../images/search_icon.png" className="search-icon"></img>
            </div>
        );
    }

    return (   
         <div id="search-bar">
            <input id="search-bar-text" type="text" placeholder="e.g Fintech" 
                onChange={e => props.setSearchText(e.target.value)} 
                onFocus={() => props.setBarSelected(true)}
                onBlur={() => props.setBarSelected(false)}
                onKeyDown={e => {
                        if(e.key == "Enter") {
                            navigate(`/results/${e.target.value}`);
                            props.setCurrentQuery(e.target.value);
                        }
                    }
                }>
            </input>
            <img src="../images/search_icon.png" className="search-icon"></img>
        </div>
    );
}