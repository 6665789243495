import React, { useState } from 'react';
import SuggestionsDropdown from "./SuggestionsDropdown";
import SearchBar from "./SearchBar";


export default function SearchSection() {

    // Create the suggestions open and search text states here and pass the setters to the search bar and suggestions dropdown.
    const [searchText, setSearchText] = useState("");
    const [barSelected, setBarSelected] = useState(false);

    return (
        <div id="search-section">
            <img src="images/site-logo.png" id="logo"></img>
            <h1>Where do you want to work?</h1>
            <SearchBar setSearchText={setSearchText} setBarSelected={setBarSelected}></SearchBar>
            <SuggestionsDropdown searchText={searchText} barSelected={barSelected}></SuggestionsDropdown>
        </div>
    );
}